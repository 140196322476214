import CtaButton from '@/components/generic/CtaButton'
import { PrismicAllNews } from '@/components/generic/NewsCard'
import SectionTitleWithLine from '@/components/generic/SectionTitleWithLine'
import Layout from '@/components/layouts/Layout'
import { SectionContainer } from '@/components/styled/Container'
import InnerContainer from '@/components/styled/InnerContainer'
import { FormatDate } from '@/utils/dates/DateFormatter'
import { graphql } from 'gatsby'
import React from 'react'

import ArrowIcon from '@/assets/images/black-right-arrow.svg'
import OriginalLang from '@/components/pages/news/OriginalLang'
import { language } from '@/helpers/Language'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import NewsList from '@/components/pages/news/NewsList'
import { media } from '@/styles/media'
import {
  Categories,
  Category,
  FeaturedBody,
  FeaturedImage,
  FeaturedInformation,
  FeaturedHeading,
  FeaturedAuthor,
  FeaturedTitleWrapper,
  FeaturedWrapper,
  NewsBar,
  StyledLink
} from '../NewsIndex'

export default function AllNewsPagination({
  data,
  pageContext
}: {
  data: PrismicAllNews
  pageContext: any
}) {
  if (!data) {
    return <div>Loading news...</div>
  }

  const { t } = useTranslation()

  const featuredPost =
    data.featured.edges.length > 0
      ? data.featured.edges[0].node.data
      : data.list.edges[0].node.data

  const featuredArticleLang = featuredPost.original_language?.toLowerCase()

  return (
    <Layout
      pageTitle={t('seo.news.title')}
      pageDescription={t('seo.news.meta_description')}
      pageCanonical={`https://ichigo.com/news/${pageContext.currentPage}`}
    >
      <NewsBar>
        <SectionContainer
          customCss={css`
            ${media.tabletLandscapeDown} {
              padding: 0 16px !important;
            }
          `}
        >
          <InnerContainer style={{ flexDirection: 'column', margin: '0 auto' }}>
            <Categories>
              <Category className="active">
                {t('news_category.all-news')}
              </Category>
              <StyledLink to="/news/press-release">
                <Category>{t('news_category.press-release')}</Category>
              </StyledLink>
              <StyledLink to="/news/media-coverage">
                <Category>{t('news_category.media-coverage')}</Category>
              </StyledLink>
              <StyledLink to="/news/announcement">
                <Category>{t('news_category.announcement')}</Category>
              </StyledLink>
            </Categories>
          </InnerContainer>
        </SectionContainer>
      </NewsBar>

      <SectionContainer
        customCss={css`
          padding-top: 96px;
          padding-bottom: 168px;
          overflow: hidden;

          ${media.tabletLandscapeDown} {
            padding: 72px 16px !important;
            padding-top: 120px !important;
          }
        `}
      >
        <InnerContainer
          style={{
            flexDirection: 'column',
            margin: '0 auto',
            paddingBottom: '96px'
          }}
        >
          <FeaturedWrapper>
            <FeaturedImage src={featuredPost.image?.url} />

            <FeaturedBody>
              <FeaturedTitleWrapper>
                <FeaturedInformation>
                  <span>{t(`news_category.${featuredPost.category}`)}</span>
                  <span>⎯</span>
                  <span>{FormatDate(featuredPost.date)}</span>
                </FeaturedInformation>

                <FeaturedAuthor>
                  {language() === featuredArticleLang
                    ? featuredPost.heading?.text
                    : featuredPost.translated_heading?.text ||
                      featuredPost.heading.text}

                  <OriginalLang lang={featuredPost.original_language} />
                </FeaturedAuthor>
              </FeaturedTitleWrapper>

              <FeaturedHeading isInternal={featuredPost.is_internal_article}>
                {language() === featuredArticleLang
                  ? featuredPost.subheading?.text
                  : featuredPost.translated_subheading?.text ||
                    featuredPost.subheading.text}
              </FeaturedHeading>

              {featuredPost.is_internal_article ? (
                <CtaButton
                  text={t('generic.read_more')}
                  bgColor="#fff"
                  color="#00121F"
                  icon={ArrowIcon}
                  to={`/news/${featuredPost.slug}`}
                  style={{
                    borderRadius: '999px',
                    border: '1px solid #29292C',
                    padding: '8px 16px'
                  }}
                />
              ) : (
                <CtaButton
                  text={t('generic.read_more')}
                  bgColor="#fff"
                  color="#00121F"
                  icon={ArrowIcon}
                  to={featuredPost.link.url}
                  target="_blank"
                  style={{
                    borderRadius: '999px',
                    border: '1px solid #29292C',
                    padding: '8px 16px'
                  }}
                />
              )}
            </FeaturedBody>
          </FeaturedWrapper>
        </InnerContainer>

        <InnerContainer
          style={{
            flexDirection: 'column',
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <SectionTitleWithLine
            text="LATEST NEWS"
            secondText="最新ニュース"
            gap="32px"
            centeredOnMobile
            bigTextOnMobile
          />

          <NewsList
            paginationBaseUrl="/news"
            data={data.list}
            pageContext={pageContext}
            haveTabTitle={false}
          />
        </InnerContainer>
      </SectionContainer>
    </Layout>
  )
}

export const query = graphql`
  query featuredAndNewsListPaginated($skip: Int) {
    featured: allPrismicNew(
      limit: 1
      filter: { data: { is_featured: { eq: true } } }
      sort: { fields: [data___article_date], order: DESC }
    ) {
      edges {
        node {
          id
          data {
            image {
              url
            }
            heading {
              text
            }
            translated_heading {
              text
            }
            subheading {
              text
            }
            translated_subheading {
              text
            }
            is_internal_article
            category
            original_language
            slug
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }

    list: allPrismicNew(
      skip: $skip
      sort: { fields: [data___article_date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          data {
            image {
              url
            }
            heading {
              text
            }
            translated_heading {
              text
            }
            subheading {
              text
            }
            translated_subheading {
              text
            }
            is_internal_article
            category
            original_language
            slug
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
